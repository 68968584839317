<template>
  <b-modal id="modal-fullscreen" modal-class="fullscreen" content-class="bg-dark-navy rounded-0 border-0" header-class="bg-navy rounded-0 border-0" body-class="scroll-overflow" scrollable hide-footer>
    <template v-slot:modal-header="{ close }">
      <b-row class="w-100">
        <b-col>
          <b-media no-body>
            <b-media-aside class="border-right mr-3 pr-3">
              <img :src="require('@/assets/images/logo-icon.svg')" class="m-auto d-block img-fluid" style="max-width:60px" />
            </b-media-aside>
            <b-media-body class="align-self-center">
              <h6 class="m-0 color-white">
                <b-badge pill class="indicator mr-2 border-0" :class="getIndicator(current.analysis.conclusion.toLowerCase())">
                  <font-awesome-icon :icon="getIcon(current.analysis.conclusion.toLowerCase())" size="lg"></font-awesome-icon> {{ getConclusion(current.analysis.conclusion.toLowerCase()) }}
                </b-badge>
                {{ current.submission.patient.demographics.name || current.submission.patient.identifier }}
              </h6>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col class="align-self-center text-right pr-0">
          <b-button size="sm" variant="link" class="color-white font-weight-400 p-0" @click="close()">
            Exit Fullscreen<i class="fas fa-times-circle color-white font-size-18 ml-2"></i>
          </b-button>
        </b-col>
      </b-row>
    </template>
    <b-row class="pb-3">
      <b-col cols="7">
        <div class="zoom-container mb-3">
          <img class="fluid" :src="selectedImage" :style="'width: 100%; filter: brightness(' + brightness + '%) contrast(' + contrast + '%) saturate(' + saturate + '%) sepia(' + sepia + '%) hue-rotate(' + hue + 'deg) invert(' + invert + '%);'">
        </div>
        </b-col>
        <b-col cols="5">
          <b-tabs pills class="modal-tabs" nav-wrapper-class="nav-wrapper rounded-0 border-0 pt-0 mb-3" content-class="" fill v-show="false">
            <b-tab active title="Image view">
              <ul class="list-unstyled row mx-n1 heatmap-list">
                <li class="col-6 px-1 mt-2">
                  <a :class="selectedHeatmap === 'original' ? 'active' : ''" href="#" @click.prevent="setHeatmap('original')">
                    <img :src="originalImage(current.submission)" class="img-fluid" />
                  </a>
                </li>
                <li class="col-6 px-1 mt-2">
                  <a :class="selectedHeatmap === 'bounded' ? 'active' : ''" href="#" @click.prevent="setHeatmap('bounded')">
                    <img :src="boundingBoxes(current.submission)" class="img-fluid" />
                  </a>
                </li>
              </ul>
            </b-tab>
              <b-tab title="Heatmap view">
                <ul class="list-unstyled row mx-n1 heatmap-list">
                  <li class="col-3 px-1 mt-2" v-for="heatmap in current.heatmaps" :key="heatmap.filename">
                    <a :class="selectedHeatmap === heatmap.filename ? 'active' : ''" href="#" @click.prevent="setHeatmap(heatmap.filename)">
                      <img :src="'data:image/png;base64,' + heatmap.image" class="img-fluid" />
                    </a>
                  </li>
                </ul>
              </b-tab>
            </b-tabs>
            <hr class="border-blue" v-show="false" />
            <b-form>
              <h6 class="color-white mb-3">Enhance image</h6>
              <b-form-group>
                <label class="color-white mb-0">Brightness</label>
                <b-input-group>
                  <template v-slot:prepend>
                    <b-input-group-text class="bg-transparent border-0">
                      <i class="fas fa-sun color-white"></i>
                    </b-input-group-text>
                  </template>
                  <b-form-input v-model="brightness" type="range" min="0" max="200" class="bg-transparent border-0 outline-removed"></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <label class="color-white mb-0">Contrast</label>
                <b-input-group>
                  <template v-slot:prepend>
                    <b-input-group-text class="bg-transparent border-0">
                      <i class="fas fa-sun color-white"></i>
                    </b-input-group-text>
                  </template>
                  <b-form-input v-model="contrast" type="range" min="0" max="200" class="bg-transparent border-0 outline-removed"></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <label class="color-white mb-0">Saturation</label>
                <b-input-group>
                  <template v-slot:prepend>
                    <b-input-group-text class="bg-transparent border-0">
                      <i class="fas fa-sun color-white"></i>
                    </b-input-group-text>
                  </template>
                  <b-form-input v-model="saturate" type="range" min="0" max="200" class="bg-transparent border-0 outline-removed"></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <label class="color-white mb-0">Sepia</label>
                <b-input-group>
                  <template v-slot:prepend>
                    <b-input-group-text class="bg-transparent border-0">
                      <i class="fas fa-sun color-white"></i>
                    </b-input-group-text>
                  </template>
                  <b-form-input v-model="sepia" type="range" min="0" max="100" class="bg-transparent border-0 outline-removed"></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <label class="color-white mb-0">Invert</label>
                <b-input-group>
                  <template v-slot:prepend>
                    <b-input-group-text class="bg-transparent border-0">
                      <i class="fas fa-sun color-white"></i>
                    </b-input-group-text>
                  </template>
                  <b-form-input v-model="invert" type="range" min="0" max="100" class="bg-transparent border-0 outline-removed"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-form>
        </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { apiRoute } from './../../../helpers'

export default {
  data () {
    return {
      selectedHeatmap: 'original',
      key: 1,
      brightness: 100,
      contrast: 100,
      saturate: 100,
      sepia: 0,
      invert: 0,
      hue: 0
    }
  },
  props: {
    current: {
      type: Object,
      default: null
    }
  },
  computed: {
    selectedImage () {
      if (this.selectedHeatmap) {
        if (this.selectedHeatmap === 'original') {
          return this.originalImage(this.current.submission)
        } else if (this.selectedHeatmap === 'bounded') {
          return this.boundingBoxes(this.current.submission)
        } else {
          const _image = this.current.heatmaps.find(heatmap => heatmap.filename === this.selectedHeatmap)
          if (_image) {
            return 'data:image/png;base64,' + _image.image
          } else {
            return null
          }
        }
      } else {
        return null
      }
    },
    filteredList () {
      if (this.current.analysis.pathologies && this.current.analysis.pathologies.length > 0) {
        const _list = this.current.analysis.pathologies.filter(_pathology => _pathology.name !== 'Thoracic' && _pathology.name !== 'Cardio')
        return _list
      } else {
        return []
      }
    }
  },
  methods: {
    setHeatmap: function (name) {
      this.selectedHeatmap = name
    },
    getConclusion: function (name) {
      if (name.indexOf('high') > -1) {
        return ''
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return ''
      } else if (name.indexOf('other') > -1) {
        return ''
      } else {
        return ''
      }
    },
    getIcon: function (name) {
      if (name.indexOf('high') > -1) {
        return 'circle'
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return 'circle'
      } else {
        return 'heartbeat'
      }
    },
    getIndicator: function (name) {
      if (name.indexOf('high') > -1) {
        return 'high'
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return 'high'
      } else {
        return 'nothing'
      }
    },
    originalImage: function (result) {
      if (result) {
        if (result.attachments[0].path.indexOf('http') === -1) {
          return apiRoute() + result.attachments[0].path
        } else {
          return result.attachments[0].path
        }
      } else {
        return null
      }
    },
    boundingBoxes: function (result) {
      if (result) {
        if (result.attachments[0].response.bounding.path.indexOf('http') === -1) {
          return apiRoute() + result.attachments[0].response.bounding.path
        } else {
          return result.attachments[0].response.bounding.path
        }
      } else {
        return null
      }
    },
    hasCardiomegaly: function (result) {
      if (result) {
        const _pathology = result.attachments[0].response.labels[0].pathologies.find(pathology => pathology.predicted_diagnosis === 'Cardio')
        if (_pathology) {
          if (_pathology.confidence > 0.5) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
}
</script>
