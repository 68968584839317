<template>
  <b-list-group flush>
    <b-list-group-item href="#" @click.prevent="selectImage('results')" :active="active === 'results'" v-if="canShowResults">
      <b-row>
        <b-col cols="2" class="pr-0 text-center my-auto">
          <font-awesome-icon icon="user-graduate" size="3x" class="text-white" />
        </b-col>
        <b-col class="d-flex flex-column">
          <b-row class="mb-1">
            <b-col cols="11">
              <h6 class="color-white pt-1 mb-0 d-inline-block text-truncate" style="width: calc(100% - 0.25rem);">
                Phase {{ phase }} results
              </h6>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase === 1 && totals">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                Time taken: <b>{{ minutes(totalTime) | zeroPad }}:{{ seconds(totalTime) | zeroPad }}.{{ milliSeconds(totalTime) | zeroPad(3) }}</b><br />
                Accuracy: <b>{{ totalAccuracy.toFixed(2) }}%</b>
              </small></p>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-else>
            <b-col>
              <b-badge pill class="ml-1 bg-green color-black font-weight-400">In progress</b-badge>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item href="#" @click.prevent="selectImage(image.image)" v-for="(image, $index) in list" :key="$index" :active="image.image === active">
      <b-row>
        <b-col cols="2" class="pr-0">
          <img :src="image.image" class="img img-fluid" />
        </b-col>
        <b-col class="d-flex flex-column">
          <b-row class="mb-1">
            <b-col cols="11">
              <h6 class="color-white pt-1 mb-0 d-inline-block text-truncate" style="width: calc(100% - 0.25rem);">
                Image {{ image.image.replace('/img', '').replace('/phaseOne/', '').replace('/phaseTwo/', '').replace('.jpg', '').replace('.png', '') }}
              </h6>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="getStats(image.image)">
            <b-col cols="12">
              <p class="mb-0 line-height-1"><small>
                Time taken: <b>{{ minutes(getStats(image.image).time) | zeroPad }}:{{ seconds(getStats(image.image).time) | zeroPad }}.{{ milliSeconds(getStats(image.image).time) | zeroPad(3) }}</b><br />
                Accuracy: <b>{{ getStats(image.image).accuracy.toFixed(2) }}%</b><br /><br />
                Diagnosis: <b>{{ getStats(image.image).diagnosis }}</b>
              </small></p>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-else>
            <b-col>
              <b-badge pill class="ml-1 bg-green color-black font-weight-400">In progress</b-badge>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    list: {
      type: Array,
      default: () => { return [] }
    },
    active: {
      type: String,
      default: null
    },
    phase: {
      type: Number,
      default: 1
    }
  },
  computed: {
    ...mapState({
      totals: state => state.totals,
      phaseOne: state => state.challenge.phaseOne,
      phaseTwo: state => state.challenge.phaseTwo,
      _user: state => state.user
    }),
    canSwitch () {
      let _canSwitch = true
      this.list.forEach(_image => {
        if (!this.getStats(_image.image)) {
          _canSwitch = false
        }
      })

      return _canSwitch
    },
    totalTime () {
      if (this.phase === 1) {
        return this.totals.scores.phaseOne.reduce((a, b) => a + b.time, 0)
      } else if (this.phase === 2) {
        return this.totals.scores.phaseTwo.reduce((a, b) => a + b.time, 0)
      } else {
        return 0
      }
    },
    totalAccuracy () {
      if (this.phase === 1) {
        return this.totals.scores.phaseOne.reduce((a, b) => a + b.accuracy, 0) / this.totals.scores.phaseOne.length
      } else if (this.phase === 2) {
        return this.totals.scores.phaseTwo.reduce((a, b) => a + b.accuracy, 0) / this.totals.scores.phaseTwo.length
      } else {
        return 0
      }
    },
    canShowResults () {
      if (this.phase === 1) {
        return this.list.length === this.phaseOne.length
      } else if (this.phase === 2) {
        return this.list.length === this.phaseTwo.length
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions('alert', {
      setWarning: 'warning'
    }),
    getStats: function (image) {
      if (this.phase === 1) {
        const _image = this.totals.scores.phaseOne.find(_image => _image.image === image)
        if (_image) {
          return _image
        } else {
          return null
        }
      } else if (this.phase === 2) {
        const _image = this.totals.scores.phaseTwo.find(_image => _image.image === image)
        if (_image) {
          return _image
        } else {
          return null
        }
      } else {
        return null
      }
    },
    minutes: function (time) {
      return Math.floor(time / 1000 / 60) % 60
    },
    seconds: function (time) {
      return Math.floor(time / 1000) % 60
    },
    milliSeconds: function (time) {
      return Math.floor(time % 1000)
    },
    selectImage: function (image) {
      if (this.canSwitch) {
        this.$emit('select-image', this.phase, image)
      } else {
        this.setWarning('You cannot switch to previous images without completing this one!')
      }
    }
  },
  filters: {
    zeroPad: function (value, num) {
      num = typeof num !== 'undefined' ? num : 2
      return value.toString().padStart(num, '0')
    },
    percentage: function (value) {
      return (value * 100).toFixed(2) + '%'
    }
  }
}
</script>
