<template>
  <b-modal id="modal-feedback" modal-class="feedback rounded-0 border-0" content-class="bg-dark-navy" header-class="bg-navy rounded-0 border-0" body-class="scroll-overflow rounded-0 border-0" scrollable hide-footer>
    <template v-slot:modal-header="{ close }">
      <b-row class="w-100-plus">
        <b-col>
          <b-media no-body>
            <b-media-aside class="border-right mr-3 pr-3">
              <img :src="require('@/assets/images/logo-icon.svg')" class="m-auto d-block img-fluid" style="max-width:60px" />
            </b-media-aside>
            <b-media-body class="align-self-center">
              <h6 class="m-0 mb-2 color-white">Analysis Feedback</h6>
              <b-badge pill class="indicator mr-2 px-2" :class="getIndicator(current.analysis.conclusion.toLowerCase())" v-if="Boolean(current.analysis.score)">
                <font-awesome-icon icon="bell" class="mr-1"></font-awesome-icon> Abnormality Score: {{ (current.analysis.score * 100).toFixed(0) }}%
              </b-badge>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col class="text-right pr-0">
          <b-button size="sm" variant="link" class="color-white font-weight-400 p-0" @click="close()">
            Exit<i class="fas fa-times-circle color-white font-size-18 ml-2"></i>
          </b-button>
        </b-col>
      </b-row>
    </template>
    <b-row>
      <b-col cols="12">
        <div class="table-responsive mb-0">
          <table class="table table-borderless table-sm color-white mb-0" width="100%">
            <thead>
              <tr class="border-bottom border-blue">
                <th class="pl-0 align-top">Abnormality</th>
                <th class="text-center" style="width: 6rem;">Present</th>
                <th class="text-center" style="width: 6rem;">Absent</th>
              </tr>
            </thead>
            <tbody :key="updated">
              <tr>
                <td class="pl-0 py-0">
                  <span>Pneumothorax</span>
                </td>
                <td class="text-center py-0">
                  <a href="#" @click.prevent="toggleConcordance('pneumothorax')">
                    <font-awesome-icon icon="square" class="color-white ml-2 font-size-14" v-if="isConcordance('pneumothorax') === false"></font-awesome-icon>
                    <font-awesome-icon icon="check-square" class="color-yellow ml-2 font-size-14" v-else></font-awesome-icon>
                  </a>
                </td>
                <td class="text-center py-0">
                  <a href="#" @click.prevent="toggleConcordance('pneumothorax')">
                    <font-awesome-icon icon="square" class="color-white ml-2 font-size-14" v-if="isConcordance('pneumothorax') === true"></font-awesome-icon>
                    <font-awesome-icon icon="check-square" class="color-yellow ml-2 font-size-14" v-else></font-awesome-icon>
                  </a>
                </td>
              </tr>
              <tr>
                <td class="pl-0 py-0">
                  <span>Pleural Effusion</span>
                </td>
                <td class="text-center py-0">
                  <a href="#" @click.prevent="toggleConcordance('pleuraleffusion')">
                    <font-awesome-icon icon="square" class="color-white ml-2 font-size-14" v-if="isConcordance('pleuraleffusion') === false"></font-awesome-icon>
                    <font-awesome-icon icon="check-square" class="color-yellow ml-2 font-size-14" v-else></font-awesome-icon>
                  </a>
                </td>
                <td class="text-center py-0">
                  <a href="#" @click.prevent="toggleConcordance('pleuraleffusion')">
                    <font-awesome-icon icon="square" class="color-white ml-2 font-size-14" v-if="isConcordance('pleuraleffusion') === true"></font-awesome-icon>
                    <font-awesome-icon icon="check-square" class="color-yellow ml-2 font-size-14" v-else></font-awesome-icon>
                  </a>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2">&nbsp;</th>
                <th class="text-center pt-2">
                  <b-button size="sm" variant="link" class="color-white font-weight-400 p-0" @click="update()">
                    <font-awesome-icon icon="upload" class="mr-1"></font-awesome-icon> Save
                  </b-button>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import { apiRoute } from './../../../helpers'

export default {
  data () {
    return {
      selectedHeatmap: 'original',
      key: 1,
      brightness: 100,
      contrast: 100,
      saturate: 100,
      sepia: 0,
      invert: 0,
      hue: 0,
      concordanceList: {},
      other_tags: [],
      updated: 0
    }
  },
  props: {
    current: {
      type: Object,
      default: null
    }
  },
  mounted () {
    if (this.current.submission && this.current.submission.attachments && this.current.submission.attachments.length > 0) {
      const attachment = this.current.submission.attachments[0]
      if (attachment.feedback) {
        this.other_tags = attachment.feedback.other || []

        if (attachment.feedback && attachment.feedback.labels && attachment.feedback.labels.length > 0) {
          const label = attachment.feedback.labels[0]
          if (label.pathologies && label.pathologies.length > 0) {
            label.pathologies.forEach(_pathology => {
              this.$set(this.concordanceList, _pathology.pathology_id, _pathology.concordance)
            })
          }
        }
      }
    }
  },
  computed: {
    selectedImage () {
      if (this.selectedHeatmap) {
        if (this.selectedHeatmap === 'original') {
          return this.originalImage(this.current.submission)
        } else if (this.selectedHeatmap === 'bounded') {
          return this.boundingBoxes(this.current.submission)
        } else {
          const _image = this.current.heatmaps.find(heatmap => heatmap.filename === this.selectedHeatmap)
          if (_image) {
            return 'data:image/png;base64,' + _image.image
          } else {
            return null
          }
        }
      } else {
        return null
      }
    },
    filteredList () {
      if (this.current.analysis.pathologies && this.current.analysis.pathologies.length > 0) {
        const _list = this.current.analysis.pathologies.filter(_pathology => _pathology.name !== 'Thoracic' && _pathology.name !== 'Cardio')
        return _list
      } else {
        return []
      }
    },
    detectedPathologies () {
      if (this.current.submission && this.current.submission.attachments && this.current.submission.attachments.length > 0) {
        const attachment = this.current.submission.attachments[0]
        if (attachment.response) {
          if (attachment.response && attachment.response.labels && attachment.response.labels.length > 0) {
            const label = attachment.response.labels[0]
            if (label.pathologies && label.pathologies.length > 0) {
              return label.pathologies
            } else {
              return []
            }
          } else {
            return []
          }
        } else {
          return []
        }
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapActions('staffSubmission', {
      saveFeedback: 'feedback'
    }),
    setHeatmap: function (name) {
      this.selectedHeatmap = name
    },
    getConclusion: function (name) {
      if (name.indexOf('high') > -1) {
        return ''
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return ''
      } else if (name.indexOf('other') > -1) {
        return ''
      } else {
        return ''
      }
    },
    getIcon: function (name) {
      if (name.indexOf('high') > -1) {
        return 'circle'
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return 'circle'
      } else {
        return 'heartbeat'
      }
    },
    getIndicator: function (name) {
      if (name.indexOf('high') > -1) {
        return 'high'
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return 'high'
      } else {
        return 'nothing'
      }
    },
    originalImage: function (result) {
      if (result) {
        if (result.attachments[0].path.indexOf('http') === -1) {
          return apiRoute() + result.attachments[0].path
        } else {
          return result.attachments[0].path
        }
      } else {
        return null
      }
    },
    boundingBoxes: function (result) {
      if (result) {
        if (result.attachments[0].response.bounding.path.indexOf('http') === -1) {
          return apiRoute() + result.attachments[0].response.bounding.path
        } else {
          return result.attachments[0].response.bounding.path
        }
      } else {
        return null
      }
    },
    hasCardiomegaly: function (result) {
      if (result) {
        const _pathology = result.attachments[0].response.labels[0].pathologies.find(pathology => pathology.predicted_diagnosis === 'Cardio')
        if (_pathology) {
          if (_pathology.confidence > 0.5) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isConcordance: function (name) {
      const _detected = this.detectedPathologies.filter(_pathology => _pathology.predicted_diagnosis === name)

      if (_detected && _detected.length > 0) {
        let _response = false
        _detected.forEach(_record => {
          if (this.concordanceList[_record._id] && !_response) {
            _response = true
          }
        })

        return _response
      } else {
        if (this.other_tags && this.other_tags.length > 0) {
          return Boolean(this.other_tags.find(_other => _other === name))
        } else {
          return false
        }
      }
    },
    toggleConcordance: function (name) {
      const _detected = this.detectedPathologies.filter(_pathology => _pathology.predicted_diagnosis === name)
      const _value = !this.isConcordance(name)

      if (_detected && _detected.length > 0) {
        _detected.forEach(_record => {
          this.$set(this.concordanceList, _record._id, _value)
        })
      } else {
        if (_value === true) {
          this.other_tags.push(name)
        } else {
          if (this.other_tags && this.other_tags.length > 0) {
            this.other_tags = this.other_tags.filter(_record => _record !== name)
          }
        }
      }

      this.updated++
    },
    update: function () {
      const _pathologies = []
      for (const _prop in this.concordanceList) {
        _pathologies.push({
          id: _prop,
          value: this.concordanceList[_prop]
        })
      }

      this.saveFeedback({
        id: this.current.submission.attachments[0].response._id,
        pathologies: _pathologies,
        other: this.other_tags,
        submission: this.current.submission._id
      })
    }
  }
}
</script>
