<template>
  <div id="MainView">
    <b-navbar toggleable="lg" type="dark" class="px-4 py-3">
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav class="mr-4">
            <b-nav-item href="https://www.edai.africa/contact/" target="_blank" link-classes="btn px-3 btn-sm btn-yellow rounded-pill">
              <font-awesome-icon icon="info" />
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="px-2 px-md-4 mt-1 inner-container">
      <b-row class="inner-row">
        <b-col cols="12" md="4" class="inner-col d-md-flex flex-column" :class="worklist ? 'd-flex' : 'd-none'">
          <!-- Accordion -->
          <div class="accordion h-100 d-flex flex-column bg-dark-navy" role="tablist">
            <!-- Accordion 1 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.now>
                <small>Phase 1:</small> Without AI Assistance
                <b-badge pill class="ml-1 bg-yellow color-black font-weight-400" v-if="!phase1 || !phaseOne || phase1.length !== phaseOne.length">Not complete</b-badge>
                <b-badge pill class="ml-1 bg-green color-black font-weight-400" v-else>Complete</b-badge>
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ phase1.length }}</b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="now" :visible="currentPhase === 1" accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <list-view :list="phase1" :active="selected ? selected : (selectedResults === 1 ? 'results' : '')" v-on:select-image="selectImage"></list-view>
            </b-collapse>
            <!-- END | Accordion 1 -->

            <!-- Accordion 2 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.earlier>
                <small>Phase 2:</small> With AI Assistance
                <b-badge pill class="ml-1 bg-yellow color-black font-weight-400" v-if="!phase2 || !phaseTwo || phase2.length !== phaseTwo.length">Not complete</b-badge>
                <b-badge pill class="ml-1 bg-green color-black font-weight-400" v-else>Complete</b-badge>
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ phase2.length }}</b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="earlier" :visible="currentPhase === 2" accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <list-view :list="phase2" :phase="2" :active="selected ? selected : (selectedResults === 2 ? 'results' : '')" v-on:select-image="selectImage"></list-view>
            </b-collapse>
            <!-- END | Accordion 2 -->
          </div>
          <!-- END | Accordion -->
        </b-col>
        <b-col cols="12" md="8" class="inner-col d-flex flex-column">
          <image-view v-if="selected" :selected="selectedImage" :phase="currentPhase" v-on:next-image="nextImage" v-on:view-results="viewResults"></image-view>
          <results-view v-else-if="selectedResults" :phase="selectedResults" :list="selectedResults === 1 ? phase1 : phase2" v-on:next-phase="nextPhase"></results-view>
          <nothing-view v-else></nothing-view>
        </b-col>
      </b-row>
    </div>
    <b-modal id="welcome" size="lg" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
      <h4 class="mb-4">Welcome to Meet the AI Challenge</h4>
      <p>In this challenge you will be presented with sets of images in two different phases:</p>
      <ol>
        <li>With this set you will be required to label each image with the pathologies that you can identify. AI will be doing the same in the background. Once you complete each image, we will be shown the results of  AI next to your results. The accuracy of the results will be measured for both against results compiled by Ground Truth paediatric radiologist.</li>
        <li>In this set you will also be required to label each image with the pathologies that you can identify, however once AI completes its own identification, the results will be displayed immediately. You can then use the AI results to augment your own before you submit each image. Combined results will be measured against the results compiled by Ground Truth paediatric radiologist.</li>
      </ol>
      <p>Time and accuracy is measured for each image, so that you can compare your own time against that of AI and the GT paediatric radiologist. Yours and AI's accuracy is measures against the results compiled by the radiologist. Total accuracy is calculated as an average of individual accuracies as you work through the images.</p>
      <p><strong class="text-white">Challenger with the highest total accuracy will be declared a winner of this challenge!</strong></p>
      <b-button type="button" pill class="btn-yellow px-5" @click.prevent="closeWelcome()">Next</b-button>
    </b-modal>
    <b-modal id="phase1" size="lg" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
      <h4 class="mb-0">Phase 1</h4>
      <p>Working through images without AI's assistance.</p>
      <p>Once you click "Start Phase 1!" you will be presented with the first image in the set. Study it carefully, then select a pathology from the dropdown list, choose the most appropriate pathologies, click mark and a box will appear over the image allowing you to select the area on the X-Ray that corresponds to the selected pathology. You can select another pathology and select another area that corresponds to this pathology.</p>
      <p>Once completed with the image, click "Submit" to view AI's results for the same image. To go to the next image, click "Next" button; if there are no pathologies that can be identified, click "No Pathologies" to go to the next image.</p>
      <p>Please repeat this process until you get to the Phase 1 summary page. As you progress through the images, your total scores are updated on the top right hand corner of the screen.</p>
      <b-button type="button" pill class="btn-yellow px-5" @click.prevent="startPhase1()">Start Phase 1!</b-button>
    </b-modal>
    <b-modal id="phase2" size="lg" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
      <h4 class="mb-0">Phase 2</h4>
      <p>Working through images with AI's assistance.</p>
      <p>Once you click "Start Phase 2!" you will be presented with the first image in the set. Like with the previous phase, you will need to identify the pathologies and provide a diagnosis, however this time AI's results will be displayed as soon as they're ready. You can toggle AI's results display on or off.</p>
      <p>Once completed with the image, click "Submit" to view the Ground truth radiologists results for the same image. To go to the next image, click "Next" button; if there are no pathologies that can be identified, click "No Pathologies" to go to the next image.</p>
      <p>Please repeat this process until you get to the Phase 2 summary page. As you progress through the images, your total scores are updated on the top right hand corner of the screen.</p>
      <b-button type="button" pill class="btn-yellow px-5" @click.prevent="startPhase2()">Start Phase 2!</b-button>
      <b-button type="button" pill class="btn-blue px-5 ml-2" @click.prevent="$bvModal.hide('phase2')">Back to Results</b-button>
    </b-modal>
  </div>
</template>

<script>
import { apiRoute, authHeader } from './../../helpers'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

import ImageView from './components/View'
import ListView from './components/List'
import NothingView from './components/Nothing'
import ResultsView from './components/ResultsView'

export default {
  components: {
    ImageView,
    ListView,
    NothingView,
    ResultsView
  },
  data () {
    return {
      current: null,
      currentPhase: 0,
      selected: null,
      selectedResults: null,
      phase1: [],
      phase2: [],
      interval: null,
      period: 'now',
      allLoaded: false,
      projectId: null,
      search: null,
      now: [],
      earlier: [],
      yesterday: [],
      week: [],
      older: [],
      key: 0,
      worklist: false
    }
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      submissions: state => state.staffSubmission,
      phaseOne: state => state.challenge.phaseOne,
      phaseTwo: state => state.challenge.phaseTwo,
      results: state => state.results,
      _user: state => state.user
    }),
    loadState () {
      return this.submissions.status.loaded
    },
    resultsLoaded () {
      return this.results.completed
    },
    selectedData () {
      if (!this.selected) {
        return null
      } else {
        let _selected = this.now.find(item => item.created_at._id === this.selected)
        if (_selected) {
          return _selected.created_at.cell
        } else {
          _selected = this.earlier.find(item => item.created_at._id === this.selected)
          if (_selected) {
            return _selected.created_at.cell
          } else {
            _selected = this.yesterday.find(item => item.created_at._id === this.selected)
            if (_selected) {
              return _selected.created_at.cell
            } else {
              _selected = this.week.find(item => item.created_at._id === this.selected)
              if (_selected) {
                return _selected.created_at.cell
              } else {
                _selected = this.older.find(item => item.created_at._id === this.selected)
                if (_selected) {
                  return _selected.created_at.cell
                } else {
                  return null
                }
              }
            }
          }
        }
      }
    },
    selectedImage () {
      if (this.selected) {
        if (this.currentPhase === 1) {
          return this.phaseOne.find(_image => _image.image === this.selected)
        } else if (this.currentPhase === 2) {
          return this.phaseTwo.find(_image => _image.image === this.selected)
        } else {
          return null
        }
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapActions('alert', {
      setWarning: 'warning'
    }),
    ...mapActions('staffSubmission', {
      getAllSubmissions: 'getCovid',
      clearSubmissions: 'clear'
    }),
    ...mapActions('results', {
      getDetails: 'getDetails',
      clearDetails: 'clear'
    }),
    ...mapActions('totals', {
      clearTotals: 'clear',
      updateImages: 'updateImages',
      updateTime: 'updateTime',
      updateAccuracy: 'updateAccuracy',
      updateScore: 'updateScore'
    }),
    closeWelcome: function () {
      localStorage.setItem('welcomedisplayed', 'Yes')
      this.$bvModal.hide('welcome')
      this.$bvModal.show('phase1')
    },
    startPhase1: function () {
      this.$bvModal.hide('phase1')
      this.current = -1
      this.currentPhase = 1
      this.selectedResults = null
      this.nextImage()
    },
    loadNextP1: function () {
      this.selected = this.phaseOne[this.current].image
      this.phase1.unshift(this.phaseOne[this.current])
    },
    loadNextP2: function () {
      this.selected = this.phaseTwo[this.current].image
      this.phase2.unshift(this.phaseTwo[this.current])
    },
    nextImage: function () {
      if (this.currentPhase === 1) {
        if (this.current + 1 < this.phaseOne.length) {
          this.current += 1
          this.selected = null
          this.$nextTick(() => {
            this.loadNextP1()
          })
        }
      } else if (this.currentPhase === 2) {
        if (this.current + 1 < this.phaseTwo.length) {
          this.current += 1
          this.selected = null
          this.$nextTick(() => {
            this.loadNextP2()
          })
        }
      }
    },
    viewResults: function () {
      if (this.currentPhase === 1) {
        if (this.phase1.length === this.phaseOne.length) {
          this.selected = null
          this.selectedResults = 1
        } else {
          this.setWarning('You haven\'t completed all the Phase 1 images yet.')
        }
      }
    },
    nextPhase: function () {
      if (this.currentPhase === 1) {
        this.$bvModal.show('phase2')
      } else {
        this.setWarning('There are no phases to this challenge. You are done!')
      }
    },
    startPhase2: function () {
      if (this.currentPhase === 1) {
        this.$bvModal.hide('phase2')
        this.current = -1
        this.currentPhase = 2
        this.selectedResults = null
        this.nextImage()
      }
    },
    selectImage: function (phase, image) {
      if (phase === 1) {
        if (image === 'results') {
          this.selected = null
          this.selectedResults = null
          this.$nextTick(() => {
            this.selectedResults = phase
          })
        } else {
          const _image = this.phaseOne.find(_image => _image.image === image)
          if (_image) {
            this.currentPhase = phase
            this.selected = null
            this.selectedResults = null
            this.$nextTick(() => {
              this.selected = _image.image
            })
          }
        }
      } else if (phase === 2) {
        if (image === 'results') {
          this.selected = null
          this.selectedResults = null
          this.$nextTick(() => {
            this.selectedResults = phase
          })
        } else {
          const _image = this.phaseTwo.find(_image => _image.image === image)
          if (_image) {
            this.currentPhase = phase
            this.selected = null
            this.selectedResults = null
            this.$nextTick(() => {
              this.selected = _image.image
            })
          }
        }
      }
    },
    refreshStudies: function () {
      /*
      this.period = 'now'
      this.getAllSubmissions(this.period)
      */
    },
    filteredList: function (period) {
      if (!this[period]) {
        return []
      } else {
        if (!this.search) {
          return this[period]
        } else {
          return this[period].filter(row => (row.patient.demographics.name || row.patient.identifier).toLowerCase().indexOf(this.search.toLowerCase()) >= 0 || row.patient.identifier.toLowerCase().indexOf(this.search.toLowerCase()) >= 0)
        }
      }
    },
    getListDates: function (period) {
      if (!this.filteredList(period) || this.filteredList(period).length === 0) {
        return {
          createdAt: new Date('2023-01-17T11:59:00.000')
        }
      } else {
        return {
          createdAt: new Date(Math.min(...this.filteredList(period).map(_record => _record.created_at.date)) - 6 * 24 * 60 * 60 * 1000),
          updatedAt: new Date(Math.max(...this.filteredList(period).map(_record => _record.created_at.date)))
        }
      }
    },
    displayResult: async function (data) {
      this.current = data

      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      }
      const _response = await fetch(apiRoute() + '/api/v1/staff/covid/heatmaps/' + this.current.submission._id, requestOptions)
      const results = await _response.json()
      this.current.heatmaps = results
    },
    getConclusion: function (name) {
      if (name.indexOf('high') > -1) {
        return 'High Probability'
      } else if (name.indexOf('intermediate') > -1) {
        return 'Intermediate Probability'
      } else if (name.indexOf('low') > -1) {
        return 'Low Probability'
      } else if (name.indexOf('other') > -1) {
        return 'Other X-Rays'
      } else {
        return 'No Pathologies Found'
      }
    },
    getConclusionClass: function (name) {
      if (name.indexOf('high') > -1) {
        return 'text-danger'
      } else if (name.indexOf('intermediate') > -1) {
        return 'text-warning'
      } else {
        return 'text-success'
      }
    },
    getCurrentClass: function (_id) {
      if (this.current && this.current.submission) {
        if (this.current.submission._id === _id) {
          console.log('Selected')
          return 'selected-row'
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    originalImage: function (result) {
      if (result) {
        return apiRoute() + result.attachments[0].path
      } else {
        return null
      }
    },
    boundingBoxes: function (result) {
      if (result) {
        return apiRoute() + result.attachments[0].response.bounding.path
      } else {
        return null
      }
    },
    hasCardiomegaly: function (result) {
      if (result) {
        const _pathology = result.attachments[0].response.labels[0].pathologies.find(pathology => pathology.predicted_diagnosis === 'cardiomegaly')
        if (_pathology) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    selectPhase: async function (id) {
      this.selected = id
    },
    onCollapseState: function (collapseId, isJustShown) {
      if (isJustShown) {
        this.projectId = collapseId
        this.selected = null
      }
    },
    toggleList: function () {
      this.worklist = !this.worklist
    }
  },
  mounted () {
    // this.getAllSubmissions(this.period)
    const _welcomedisplayed = localStorage.getItem('welcomedisplayed')
    if (_welcomedisplayed !== 'Yes') {
      this.$bvModal.show('welcome')
    } else {
      this.clearDetails()
      this.getDetails(this._user.info._id)
    }

    this.$root.$on('bv::collapse::state', this.onCollapseState)
  },
  destroyed () {
    this.$root.$off('bv::collapse::state', this.onCollapseState)

    clearInterval(this.interval)
    this.interval = null
  },
  watch: {
    loadState (loaded) {
      if (loaded && this.alert.message == null && this.alert.type == null) {
        const getSort = function (name) {
          if (name.indexOf('high') > -1) {
            return 5
          } else if (name.indexOf('intermediate') > -1) {
            return 4
          } else if (name.indexOf('low') > -1) {
            return 3
          } else {
            return 0
          }
        }

        const rowData = this.submissions.info[this.period]
        if (rowData) {
          this[this.period] = []
          rowData.forEach(row => {
            if (!row.submission.patient) {
              row.submission.patient = {
                _id: row.submission._id,
                clinical: {},
                created_at: row.submission.created_at,
                demographics: {
                  ageGroup: 'Unknown',
                  country: 'Unknown',
                  gender: 'Unknown',
                  province: 'Unknown'
                },
                identifier: row.submission.study_id.replace('.png', '')
              }
            }

            const _record = {
              created_at: { date: moment(row.submission.created_at).toDate(), raw_date: moment(row.submission.created_at), _id: row.submission._id, cell: row },
              conclusion: row.analysis.conclusion.toLowerCase(),
              type: undefined,
              score: row.analysis.score,
              patient: row.submission.patient,
              sort: getSort(row.analysis.conclusion.toLowerCase()),
              feedback: false
            }

            if (row.submission.attachments && row.submission.attachments.length > 0 && row.submission.attachments[0].feedback) {
              _record.feedback = true
            }

            this[this.period].push(_record)
          })

          this[this.period].sort((a, b) => {
            if (a.sort < b.sort) {
              return -1
            } else if (a.sort === b.sort) {
              return (a.created_at.raw_date < b.created_at.raw_date) ? -1 : 1
            } else {
              return 1
            }
          })

          if (this.period === 'now') {
            this.period = 'earlier'
            this.getAllSubmissions(this.period)
          } else if (this.period === 'earlier') {
            if (!this.allLoaded) {
              this.period = 'yesterday'
              this.getAllSubmissions(this.period)
            }
          } else if (this.period === 'yesterday') {
            if (!this.allLoaded) {
              this.period = 'week'
              this.getAllSubmissions(this.period)
            }
          } else if (this.period === 'week') {
            if (!this.allLoaded) {
              this.period = 'older'
              this.getAllSubmissions(this.period)
            }
          } else {
            this.allLoaded = true
            const self = this
            if (!this.interval) {
              this.interval = setInterval(() => {
                self.period = 'now'
                self.getAllSubmissions(this.period)
              }, 30000)
            }
          }
        } else {
          if (this.submissions.info && this.submissions.info._id) {
            const _id = this.submissions.info._id
            const _record = this.submissions.info[_id]

            const _periods = ['now', 'earlier', 'yesterday', 'week', 'older']
            _periods.forEach(_period => {
              let _previous = this[_period].find(__record => __record.created_at._id === _id)
              if (_previous) {
                const _index = this[_period].findIndex(__record => __record.created_at._id === _id)
                if (!_record.submission.patient) {
                  _record.submission.patient = {
                    _id: _record.submission._id,
                    clinical: {},
                    created_at: _record.submission.created_at,
                    demographics: {
                      ageGroup: 'Unknown',
                      country: 'Unknown',
                      gender: 'Unknown',
                      province: 'Unknown'
                    },
                    identifier: _record.submission.study_id.replace('.png', '')
                  }
                }

                _previous = {
                  created_at: { date: moment(_record.submission.created_at).toDate(), raw_date: moment(_record.submission.created_at), _id: _record.submission._id, cell: _record },
                  conclusion: _record.analysis.conclusion.toLowerCase(),
                  type: undefined,
                  score: _record.analysis.score,
                  patient: _record.submission.patient,
                  sort: getSort(_record.analysis.conclusion.toLowerCase()),
                  feedback: false
                }

                if (_record.submission.attachments && _record.submission.attachments.length > 0 && _record.submission.attachments[0].feedback) {
                  _previous.feedback = true
                }

                this.$set(this[_period], _index, _previous)
                this.selected = null
                this.$nextTick(() => {
                  this.selected = _id
                })
              }
            })
          }
        }
      }
    },
    resultsLoaded (loaded) {
      if (loaded) {
        const _details = this.results.info.details
        if (_details && _details.scores && _details.scores.length > 0) {
          const _scores = _details.scores
          if (_scores.length >= 1) {
            if (_scores[0].images && _scores[0].images.length <= this.phaseOne.length) {
              this.clearTotals()
              let __image = null
              _scores[0].images.forEach(_image => {
                __image = this.phaseOne.find(_record => _record.image === _image.image)
                if (__image) {
                  this.updateImages()
                  this.updateTime(_image.time)
                  this.updateAccuracy({
                    _id: this._user.info._id,
                    accuracy: _image.accuracy
                  })
                  this.updateScore({
                    _id: this._user.info._id,
                    phase: 1,
                    score: {
                      image: _image.image,
                      time: _image.time,
                      accuracy: _image.accuracy,
                      pathologies: _image.pathologies,
                      diagnosis: _image.diagnosis
                    },
                    callService: false
                  })
                  this.phase1.unshift(__image)
                }
              })

              if (_scores.length === 1) {
                if (_scores[0].images && _scores[0].images.length === this.phaseOne.length) {
                  this.currentPhase = 1
                  this.selectedResults = 1
                } else {
                  this.currentPhase = 1
                  this.current = this.phase1.length - 1
                  this.$nextTick(() => {
                    this.selected = __image.image
                  })
                }
              }
            }
          }

          if (_scores.length >= 2) {
            if (_scores[1].images && _scores[1].images.length <= this.phaseTwo.length) {
              let __image = null
              _scores[1].images.forEach(_image => {
                __image = this.phaseTwo.find(_record => _record.image === _image.image)
                if (__image) {
                  this.updateImages()
                  this.updateTime(_image.time)
                  this.updateAccuracy({
                    _id: this._user.info._id,
                    accuracy: _image.accuracy
                  })
                  this.updateScore({
                    _id: this._user.info._id,
                    phase: 2,
                    score: {
                      image: _image.image,
                      time: _image.time,
                      accuracy: _image.accuracy,
                      pathologies: _image.pathologies,
                      diagnosis: _image.diagnosis
                    },
                    callService: false
                  })
                  this.phase2.unshift(__image)
                }
              })

              if (_scores.length === 2) {
                if (_scores[1].images && _scores[1].images.length === this.phaseTwo.length) {
                  this.currentPhase = 2
                  this.selectedResults = 2
                } else {
                  this.currentPhase = 2
                  this.current = this.phase2.length - 1
                  this.$nextTick(() => {
                    this.selected = __image.image
                  })
                }
              }
            }
          }
        } else {
          this.$bvModal.show('phase1')
        }
      }
    },
    selected: function (value) {
      console.log(value)
    }
  }
}
</script>
