<template>
  <b-card no-body class="h-100 bg-dark-navy custom border-0">
    <template v-slot:header>
      <b-row>
        <b-col cols="4" class="mt-1">
          <font-awesome-icon icon="stopwatch" class="mr-1"></font-awesome-icon>
          {{ minutes(totalTime) | zeroPad }}:{{ seconds(totalTime) | zeroPad }}.{{ milliSeconds(totalTime) | zeroPad(3) }}
        </b-col>
        <b-col class="text-right">
          <h6 class="color-white pt-1 mb-0">
            Phase {{ phase }} Results
          </h6>
        </b-col>
      </b-row>
    </template>
    <b-card-body class="h-100 p-0" :key="phase">
      <div class="px-4 py-2">
        <b-table striped hover :items="resultsList" class="d-none d-md-block">
          <template #cell(image)="data">
            <strong class="text-white">{{ data.item.image }}</strong>
          </template>
          <template #cell(accuracy)="data">
            <b-badge pill class="py-1 px-2 font-weight-900" :class="data.item.accuracy < 60 ? 'bg-red color-white' : (data.item.accuracy < 80 ? 'bg-yellow color-black' : 'bg-green color-black')">
              {{ data.item.accuracy.toFixed(2) }}%
            </b-badge>
          </template>
          <template #cell(comparison)="data">
            <font-awesome-icon v-if="data.item.others" :icon="getComaprison(data.item)" :class="getComaprison(data.item) === 'arrow-up' ? 'color-green' : 'color-red'"></font-awesome-icon>
          </template>
          <template #cell(others)="data">
            <b-badge v-if="data.item.others && data.item.others.data && data.item.others.data.accuracy" pill class="py-1 px-2 font-weight-900" :class="data.item.others.data.accuracy.avg < 60 ? 'bg-red color-white' : (data.item.others.data.accuracy.avg < 80 ? 'bg-yellow color-black' : 'bg-green color-black')">
              {{ data.item.others.data.accuracy.avg.toFixed(2) }}%
            </b-badge>
          </template>
          <template #cell(time)="data">
            <small>{{ minutes(data.item.time) | zeroPad }}:{{ seconds(data.item.time) | zeroPad }}.{{ milliSeconds(data.item.time) | zeroPad(3) }}</small>
          </template>
          <template #cell(diagnosis)="data">
            {{ data.item.diagnosis }}
          </template>
          <template #cell(referenceDiagnosis)="data">
            {{ data.item.referenceDiagnosis.reference.diagnosis }}
          </template>
        </b-table>
        <b-table striped hover :items="resultsListMobile" class="d-block d-md-none">
          <template #cell(image)="data">
            <strong class="text-white">{{ data.item.image }}</strong>
          </template>
          <template #cell(accuracy)="data">
            <b-badge pill class="py-1 px-2 font-weight-900" :class="data.item.accuracy < 60 ? 'bg-red color-white' : (data.item.accuracy < 80 ? 'bg-yellow color-black' : 'bg-green color-black')">
              {{ data.item.accuracy.toFixed(2) }}%
            </b-badge>
          </template>
          <template #cell(comparison)="data">
            <font-awesome-icon v-if="data.item.others" :icon="getComaprison(data.item)" :class="getComaprison(data.item) === 'arrow-up' ? 'color-green' : 'color-red'"></font-awesome-icon>
          </template>
          <template #cell(others)="data">
            <b-badge v-if="data.item.others && data.item.others.data && data.item.others.data.accuracy" pill class="py-1 px-2 font-weight-900" :class="data.item.others.data.accuracy.avg < 60 ? 'bg-red color-white' : (data.item.others.data.accuracy.avg < 80 ? 'bg-yellow color-black' : 'bg-green color-black')">
              {{ data.item.others.data.accuracy.avg.toFixed(2) }}%
            </b-badge>
          </template>
        </b-table>
        <b-button type="button" pill class="mt-3 mb-1 btn-sm btn-yellow px-5" @click.prevent="nextPhase()" v-if="phase === 1">Onto Phase 2!</b-button>
      </div>
    </b-card-body>
    <template v-slot:footer>
      <b-row>
        <b-col cols="4" class="text-center">
          Images Completed: <strong class="text-white">{{ totals.images }}</strong>
        </b-col>
        <b-col cols="4" class="text-center">
          Total time taken: <strong class="text-white">{{ formatTime(totals.time) }}</strong>
        </b-col>
        <b-col cols="4" class="text-center">
          Average accuracy: <strong class="text-white">{{ formatAccuracy(totals.accuracy) }}</strong>
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>

<style>
.bottom-to-top {
  writing-mode: vertical-lr;
  text-orientation: mixed;
}
</style>

<script>
// import Lightbox from 'vue-lightbox'
// import moment from 'moment'
import { detailService } from '../../../services'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      imageResults: []
    }
  },
  props: {
    list: {
      type: Array,
      default: () => { return [] }
    },
    phase: {
      type: Number,
      default: null
    }
  },
  created () {
    if (this.phase && this.list && this.list.length > 0) {
      for (let i = 0; i < this.list.length; i++) {
        const self = this
        detailService.getImage(this._user.info._id, this.list[i].image)
          .then(
            image => {
              if (image) {
                self.imageResults.push({
                  image: self.list[i].image,
                  data: image
                })
              }
            }
          )
      }
    }
  },
  computed: {
    ...mapState({
      phaseOne: state => state.challenge.phaseOne,
      phaseTwo: state => state.challenge.phaseTwo,
      totals: state => state.totals,
      _user: state => state.user
    }),
    totalTime () {
      if (this.phase === 1) {
        return this.totals.scores.phaseOne.reduce((a, b) => a + b.time, 0)
      } else if (this.phase === 2) {
        return this.totals.scores.phaseTwo.reduce((a, b) => a + b.time, 0)
      } else {
        return 0
      }
    },
    resultsList () {
      if (this.phase === 1) {
        return this.totals.scores.phaseOne.map(_record => {
          const _reference = this.phaseOne.find(_image => _image.image === _record.image)
          const _others = this.imageResults.find(_image => _image.image === _record.image)
          return {
            image: _record.image.replace('/img', '').replace('/phaseOne/', '').replace('/phaseTwo/', '').replace('.jpg', '').replace('.png', ''),
            accuracy: _record.accuracy,
            comparison: _others,
            others: _others,
            time: _record.time,
            diagnosis: _record.diagnosis,
            referenceDiagnosis: _reference
          }
        })
      } else if (this.phase === 2) {
        return this.totals.scores.phaseTwo.map(_record => {
          const _reference = this.phaseTwo.find(_image => _image.image === _record.image)
          const _others = this.imageResults.find(_image => _image.image === _record.image)
          return {
            image: _record.image.replace('/img', '').replace('/phaseOne/', '').replace('/phaseTwo/', '').replace('.jpg', '').replace('.png', ''),
            accuracy: _record.accuracy,
            comparison: _others,
            others: _others,
            time: _record.time,
            diagnosis: _record.diagnosis,
            referenceDiagnosis: _reference
          }
        })
      } else {
        return []
      }
    },
    resultsListMobile () {
      if (this.phase === 1) {
        return this.totals.scores.phaseOne.map(_record => {
          const _others = this.imageResults.find(_image => _image.image === _record.image)
          return {
            image: _record.image.replace('/img', '').replace('/phaseOne/', '').replace('/phaseTwo/', '').replace('.jpg', '').replace('.png', ''),
            accuracy: _record.accuracy,
            comparison: _others,
            others: _others
          }
        })
      } else if (this.phase === 2) {
        return this.totals.scores.phaseTwo.map(_record => {
          const _others = this.imageResults.find(_image => _image.image === _record.image)
          return {
            image: _record.image.replace('/img', '').replace('/phaseOne/', '').replace('/phaseTwo/', '').replace('.jpg', '').replace('.png', ''),
            accuracy: _record.accuracy,
            comparison: _others,
            others: _others
          }
        })
      } else {
        return []
      }
    }
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix
      } else {
        return text
      }
    },
    zeroPad: function (value, num) {
      num = typeof num !== 'undefined' ? num : 2
      return value.toString().padStart(num, '0')
    },
    percentage: function (value) {
      return (value * 100).toFixed(2) + '%'
    }
  },
  methods: {
    formatTime: function (duration) {
      const hours = Math.floor(duration / 1000 / 60 / 60)
      const minutes = Math.floor(duration / 1000 / 60) % 60
      const seconds = Math.floor(duration / 1000) % 60
      return (hours > 0 ? hours + 'h ' : '') + minutes + 'm ' + seconds + 's'
    },
    formatAccuracy: function (accuracy) {
      return accuracy.toFixed(2) + '%'
    },
    minutes: function (time) {
      return Math.floor(time / 1000 / 60) % 60
    },
    seconds: function (time) {
      return Math.floor(time / 1000) % 60
    },
    milliSeconds: function (time) {
      return Math.floor(time % 1000)
    },
    nextPhase: function () {
      this.$emit('next-phase', true)
    },
    getComaprison: function (data) {
      if (data.others && data.others.data && data.others.data.accuracy) {
        if (data.accuracy > data.others.data.accuracy.avg) {
          return 'arrow-up'
        } else {
          return 'arrow-down'
        }
      } else {
        return null
      }
    }
  },
  watch: {
    current: function () {
      this.brightness = 100
      this.contrast = 100
      this.saturate = 100
      this.sepia = 0
      this.invert = 0
      this.hue = 0
    }
  }
}
</script>
