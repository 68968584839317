<template>
  <b-card no-body class="h-100 bg-dark-navy custom border-0" v-if="current">
    <template v-slot:header>
      <b-row>
        <b-col cols="6" md="4" class="mt-1 d-none d-md-block">
          <font-awesome-icon icon="stopwatch" class="mr-1"></font-awesome-icon>
          {{ minutes.me | zeroPad }}:{{ seconds.me | zeroPad }}.{{ milliSeconds.me | zeroPad(3) }}
          <br class="d-block d-md-none" />
          <font-awesome-icon icon="microchip" class="ml-md-2 mr-1"></font-awesome-icon>
          {{ minutes.edai | zeroPad }}:{{ seconds.edai | zeroPad }}.{{ milliSeconds.edai | zeroPad(3) }}
        </b-col>
        <b-col cols="6" md="4" class="mt-1 d-block d-md-none">
          <font-awesome-icon icon="stopwatch" class="mr-1"></font-awesome-icon>
          {{ minutes.me | zeroPad }}:{{ seconds.me | zeroPad }}
          <br class="d-block d-md-none" />
          <font-awesome-icon icon="microchip" class="ml-md-2 mr-1"></font-awesome-icon>
          {{ minutes.edai | zeroPad }}:{{ seconds.edai | zeroPad }}
        </b-col>
        <b-col cols="4" md="4" class="text-center d-md-block d-none">
          <h6 class="color-white pt-1 mb-0 d-none d-md-block">
            Image {{ selected.image.replace('/img', '').replace('/phaseOne/', '').replace('/phaseTwo/', '').replace('.jpg', '').replace('.png', '') }}
          </h6>
        </b-col>
        <b-col cols="6" md="4" class="text-right">
          <b-button-group size="sm">
            <b-button class="font-weight-400 bg-transparent" @click.prevent="toggle('enhancement')">
              Tools <font-awesome-icon icon="cog" class="ml-1"></font-awesome-icon>
            </b-button>
          </b-button-group>
          <b-button-group size="sm" v-if="!accuracy.calculated">
            <b-button class="font-weight-400 bg-transparent" @click.prevent="toggle('pathologies')">
              Pathologies <font-awesome-icon icon="comment-medical" class="ml-1"></font-awesome-icon>
            </b-button>
          </b-button-group>
          <b-button-group size="sm" v-if="!accuracy.calculated">
            <b-button class="font-weight-400 bg-transparent" @click.prevent="toggle('diagnosis')">
              Diagnosis <font-awesome-icon icon="user-injured" class="ml-1"></font-awesome-icon>
            </b-button>
          </b-button-group>
          <b-button-group size="sm" v-if="accuracy.calculated">
            <b-button class="font-weight-400 bg-transparent" @click.prevent="toggle('results')">
              Results <font-awesome-icon icon="poll" class="ml-1"></font-awesome-icon>
            </b-button>
          </b-button-group>
        </b-col>
        <b-col cols="12" md="4" class="text-center d-block d-md-none">
          <h6 class="color-white pt-1 mb-0">
            Image {{ selected.image.replace('/img', '').replace('/phaseOne/', '').replace('/phaseTwo/', '').replace('.jpg', '').replace('.png', '') }}
          </h6>
        </b-col>
      </b-row>
    </template>
    <b-card-body class="h-100 p-0">
      <b-row>
        <b-col ref="labeller">
          <div :style="labellerStyle" class="mx-auto" :key="labellerKey">
            <vue-cropper
              ref="cropper"
              :src="''"
              alt="Original Image"
              :auto-crop-area="0.5"
              :cropmove="cropImage"
              :responsive="true"
              :movable="false"
              :zoomable="false"
              :crossorigin="'*'"
              class="mt-3"
              :ready="onReady"
              :style="'filter: brightness(' + brightness + '%) contrast(' + contrast + '%) saturate(' + saturate + '%) sepia(' + sepia + '%) hue-rotate(' + hue + 'deg) invert(' + invert + '%);'"
            ></vue-cropper>
            <bounded-image :w="dimensions.w" :h="dimensions.h" :l="canvasLeft" ref="canvas" v-if="!newPathology.active && !accuracy.calculated && showOverlay" :key="boundedImageKey" class="mt-3">
              <bounded-box
                v-for="(pathology, index) in pathologies"
                v-bind:key="index"
                :x="pathology.coords.x * dimensions.ratio"
                :y="pathology.coords.y * dimensions.ratio"
                :w="pathology.coords.w * dimensions.ratio"
                :h="pathology.coords.h * dimensions.ratio"
                :pathology="pathology.name + (pathology.name === 'Other' ? ' (' + pathology.other + ')' : '')"
                :colour="colours[index]"
              ></bounded-box>
            </bounded-image>
            <bounded-image :w="dimensions.w" :h="dimensions.h" :l="canvasLeft" ref="canvasResults" v-if="accuracy.calculated" :key="boundedImageKey" class="mt-3">
              <bounded-box
                v-for="(pathology, index) in combined"
                v-bind:key="index"
                :x="pathology.coords.x * dimensions.ratio"
                :y="pathology.coords.y * dimensions.ratio"
                :w="pathology.coords.w * dimensions.ratio"
                :h="pathology.coords.h * dimensions.ratio"
                :pathology="pathology.name + (pathology.name === 'Other' ? ' (' + pathology.other + ')' : '')"
                :colour="pathology.colour"
              ></bounded-box>
            </bounded-image>
            <bounded-image :w="dimensions.w" :h="dimensions.h" :l="canvasLeft" ref="canvasAi" v-if="!newPathology.active && !accuracy.calculated && showAiOverlay" :key="showAiOverlay" class="mt-3">
              <bounded-box
                v-for="(pathology, index) in selected.edai.pathologies"
                v-bind:key="index"
                :x="pathology.coords.x * dimensions.ratio"
                :y="pathology.coords.y * dimensions.ratio"
                :w="pathology.coords.w * dimensions.ratio"
                :h="pathology.coords.h * dimensions.ratio"
                :pathology="'AI: ' + pathology.name"
                :colour="edai[index]"
              ></bounded-box>
            </bounded-image>
          </div>
          <div class="loader" v-if="loading">
            <b-row>
              <b-col offset="5" cols="2" class="text-center">
                <b-spinner class="spinner" variant="warning" label="Spinning"></b-spinner><br />
                <span class="text-warning">Loading image.</span>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
    <template v-slot:footer>
      <b-row>
        <b-col cols="4" class="text-center">
          Images Completed:<br class="d-block d-md-none" /> <strong class="text-white">{{ totals.images }}</strong>
        </b-col>
        <b-col cols="4" class="text-center">
          Total time taken:<br class="d-block d-md-none" /> <strong class="text-white">{{ formatTime(totals.time) }}</strong>
        </b-col>
        <b-col cols="4" class="text-center">
          Average accuracy:<br class="d-block d-md-none" /> <strong class="text-white">{{ formatAccuracy(totals.accuracy) }}</strong>
        </b-col>
      </b-row>
    </template>

    <feedback :current="current"></feedback>
    <full-screen :current="current"></full-screen>
    <div class="slidein enhancement bg-navy p-3 mt-2" ref="enhancements" :class="open.enhancement ? 'open' : ''" :style="'top: ' + enhancementTop + 'rem !important;'">
      <b-button size="sm" variant="link" class="color-white font-weight-400 p-0 float-right" @click="toggle('enhancement')">
        <i class="fas fa-times-circle color-white font-size-18 ml-2"></i>
      </b-button>
      <h6 class="text-white mb-2">Image tools</h6>
      <b-row>
        <b-col cols="3" class="pt-1">Brightness</b-col>
        <b-col>
          <b-input-group>
            <template v-slot:prepend>
              <b-input-group-text class="bg-transparent border-0">
                <i class="fas fa-sun color-white"></i>
              </b-input-group-text>
            </template>
            <b-form-input v-model="brightness" type="range" size="sm" min="0" max="200" class="bg-transparent border-0 outline-removed"></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3" class="pt-1">Contrast</b-col>
        <b-col>
          <b-input-group>
            <template v-slot:prepend>
              <b-input-group-text class="bg-transparent border-0">
                <i class="fas fa-sun color-white"></i>
              </b-input-group-text>
            </template>
            <b-form-input v-model="contrast" type="range" size="sm" min="0" max="200" class="bg-transparent border-0 outline-removed"></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3" class="pt-1">Saturation</b-col>
        <b-col>
          <b-input-group>
            <template v-slot:prepend>
              <b-input-group-text class="bg-transparent border-0">
                <i class="fas fa-sun color-white"></i>
              </b-input-group-text>
            </template>
            <b-form-input v-model="saturate" type="range" size="sm" min="0" max="200" class="bg-transparent border-0 outline-removed"></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3" class="pt-1">Sepia</b-col>
        <b-col>
          <b-input-group>
            <template v-slot:prepend>
              <b-input-group-text class="bg-transparent border-0">
                <i class="fas fa-sun color-white"></i>
              </b-input-group-text>
            </template>
            <b-form-input v-model="sepia" type="range" size="sm" min="0" max="200" class="bg-transparent border-0 outline-removed"></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3" class="pt-1">Invert</b-col>
        <b-col>
          <b-input-group>
            <template v-slot:prepend>
              <b-input-group-text class="bg-transparent border-0">
                <i class="fas fa-sun color-white"></i>
              </b-input-group-text>
            </template>
            <b-form-input v-model="invert" type="range" size="sm" min="0" max="200" class="bg-transparent border-0 outline-removed"></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
    </div>
    <div class="slidein pathologies bg-navy p-3 mt-2" :class="open.pathologies ? 'open' : ''" :style="'top: ' + pathologiesTop + 'rem !important;'">
      <b-button size="sm" variant="link" class="color-white font-weight-400 p-0 float-right" @click="toggle('pathologies')">
        <i class="fas fa-times-circle color-white font-size-18 ml-2"></i>
      </b-button>
      <div v-if="showAiPathologies" class="mb-3">
        <h6 class="text-white mb-2">
          <a href="#" class="text-white mr-1" @click.prevent="toggleAiOverlay()"><font-awesome-icon :icon="showAiOverlay ? 'eye-slash' : 'eye'" :class="showAiOverlay ? null : 'color-yellow'"></font-awesome-icon></a>
          AI Pathologies
        </h6>
        <p v-if="selected.edai.pathologies.length === 0">No pathologies marked. Please, select a pathology from a dropdown list below and mark its location on the image.</p>
        <b-row :style="'color: ' + edai[index]" class="mb-2" v-for="(pathology, index) in selected.edai.pathologies" v-bind:key="index">
          <b-col cols="1"><font-awesome-icon icon="circle" /></b-col>
          <b-col>
            {{ pathology.name }} <span v-if="pathology.name === 'Other'">({{ pathology.other }})</span>
          </b-col>
        </b-row>
      </div>
      <h6 class="text-white mb-2">
        Pathologies
        <a href="#" class="text-white ml-1" @click.prevent="toggleOverlay()"><font-awesome-icon :icon="showOverlay ? 'eye-slash' : 'eye'" :class="showAiOverlay ? null : 'color-yellow'"></font-awesome-icon></a>
      </h6>
      <p v-if="pathologies.length === 0">No pathologies marked. Please, select a pathology from a dropdown list below and mark its location on the image.</p>
      <b-row :style="'color: ' + colours[index]" class="mb-2" v-for="(pathology, index) in pathologies" v-bind:key="index">
        <b-col cols="1"><font-awesome-icon icon="circle" /></b-col>
        <b-col cols="7">
          {{ pathology.name }} <span v-if="pathology.name === 'Other'">({{ pathology.other }})</span>
        </b-col>
        <b-col cols="4" v-show="!newPathology.active" class="text-right">
          <small><a href="#" @click.prevent="edit(index)" class="text-primary"><font-awesome-icon icon="edit"></font-awesome-icon> Edit</a> | <a href="#" @click.prevent="remove(index)" class="text-danger"><font-awesome-icon icon="times"></font-awesome-icon> Delete</a></small>
        </b-col>
      </b-row>
      <h6 class="text-white my-2">New Pathology</h6>
      <b-form inline @submit.prevent="onSumbit">
        <b-form-select class="mb-2 mr-sm-2 mb-sm-0" id="pathology" :value="null" size="sm" v-model="newPathology.name" :options="list" required :disabled="newPathology.active">
          <option slot="first" :value="null" disabled>Select Pathology ...</option>
        </b-form-select>
        <b-form-input v-model="newPathology.other" size="sm" class="mr-2" placeholder="Other pathology name ..." v-if="newPathology.name === 'Other'"></b-form-input>
        <b-button type="submit" size="sm" class="mr-2" variant="success">{{ newPathology.active ? (newPathology.index !== null ? 'Save' : 'Add') : 'Mark' }}</b-button>
        <b-button variant="danger" size="sm" @click="cancelEdit()" v-if="newPathology.active">Cancel</b-button>
      </b-form>
    </div>
    <div class="slidein diagnosis bg-navy py-2 px-3 mt-2" :class="open.diagnosis ? 'open' : ''" :style="'top: ' + diagnosisTop + 'rem !important;'">
      <b-button size="sm" variant="link" class="color-white font-weight-400 p-0 float-right" @click="toggle('diagnosis')">
        <i class="fas fa-times-circle color-white font-size-18 ml-2"></i>
      </b-button>
      <h6 class="text-white mb-3">Diagnosis</h6>
      <b-form @submit.prevent="submitImage" class="form-plain">
        <b-form-textarea
          id="diagnosis"
          v-model="diagnosis"
          placeholder="Please provide patient's diagnosis here ..."
          rows="2"
          max-rows="2"
          required
        ></b-form-textarea>
        <b-button type="submit" pill class="mt-2 mb-0 btn-sm btn-yellow px-5">Save &amp; Show Results!</b-button>
        <b-button type="button" pill class="mt-2 ml-2 mb-0 btn-sm btn-blue px-5" @click.prevent="noPathologies()">No pathologies</b-button>
      </b-form>
    </div>
    <div class="slidein results bg-navy py-2 px-3 mt-2" :class="open.results ? 'open' : ''" v-if="accuracy.calculated" :style="'top: ' + resultsTop + 'rem !important;'">
      <b-button size="sm" variant="link" class="color-white font-weight-400 p-0 float-right" @click="toggle('results')">
        <i class="fas fa-times-circle color-white font-size-18 ml-2"></i>
      </b-button>
      <h6 class="text-white mb-3">Results</h6>
      <p class="text-white font-plus-20 mt-3 mb-2 border-bottom-white">Accuracy: <b class="ml-1">{{ accuracy.me | percentage }}</b></p>
      <b-row>
        <b-col cols="4">
          <b-button type="button" pill class="mb-1 btn-sm btn-blue btn-fill px-2" @click.prevent="select('me')">
            Me
            <font-awesome-icon :icon="selectedDisplay === 'me' ? 'eye-slash' : 'eye'" class="ml-1"></font-awesome-icon>
          </b-button>
        </b-col>
        <b-col cols="4">
          <b-button type="button" pill class="mb-1 btn-sm btn-yellow btn-fill px-2 d-none d-md-block" @click.prevent="select('edai')">
            AI Assistant
            <font-awesome-icon :icon="selectedDisplay === 'edai' ? 'eye-slash' : 'eye'" class="ml-1"></font-awesome-icon>
          </b-button>
          <b-button type="button" pill class="mb-1 btn-sm btn-yellow btn-fill px-2 d-block d-md-none" @click.prevent="select('edai')">
            AI
            <font-awesome-icon :icon="selectedDisplay === 'edai' ? 'eye-slash' : 'eye'" class="ml-1"></font-awesome-icon>
          </b-button>
        </b-col>
        <b-col cols="4">
          <b-button type="button" pill class="mb-1 btn-sm btn-green btn-fill px-2 d-none d-md-block" @click.prevent="select('reference')">
            Ground Truth
            <font-awesome-icon :icon="selectedDisplay === 'reference' ? 'eye-slash' : 'eye'" class="ml-1"></font-awesome-icon>
          </b-button>
          <b-button type="button" pill class="mb-1 btn-sm btn-green btn-fill px-2 d-block d-md-none" @click.prevent="select('reference')">
            GT
            <font-awesome-icon :icon="selectedDisplay === 'reference' ? 'eye-slash' : 'eye'" class="ml-1"></font-awesome-icon>
          </b-button>
        </b-col>
      </b-row>
      <p class="text-white font-plus-20 mt-3 mb-2 border-bottom-white">Pathologies</p>
      <b-row :style="'color: ' + pathology.colour" class="mb-0" v-for="(pathology, index) in combined" v-bind:key="index">
        <b-col cols="1"><font-awesome-icon icon="circle" /></b-col>
        <b-col cols="7">
          {{ pathology.name }} <span v-if="pathology.name === 'Other'">({{ pathology.other }})</span>
        </b-col>
      </b-row>
      <p class="text-white font-plus-20 mt-3 mb-2 border-bottom-white">Diagnosis</p>
      <b-row>
        <b-col cols="4">
          Me:
        </b-col>
        <b-col>
          <strong class="text-white">{{ diagnosis }}</strong>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          Ground Truth:
        </b-col>
        <b-col>
          <strong class="text-white">{{ selected.reference.diagnosis }}</strong>
        </b-col>
      </b-row>
      <p class="text-white font-plus-20 mt-3 mb-2 border-bottom-white">Challenge comparison</p>
      <div v-if="imageResults">
        <b-row class="mb-1">
          <b-col>
            <p class="mb-0">You're participant #{{ imageResults.challengers + 1 }} to complete this image.</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Accuracy:
          </b-col>
          <b-col v-if="imageResults.challengers > 1">
            <b-row>
              <b-col cols="4">
                Min: <strong class="text-white">{{ imageResults.accuracy.min / 100 | percentage }}</strong>
              </b-col>
              <b-col cols="4">
                Avg: <strong class="text-white">{{ imageResults.accuracy.avg / 100 | percentage }}</strong>
              </b-col>
              <b-col cols="4">
                Max: <strong class="text-white">{{ imageResults.accuracy.max / 100 | percentage }}</strong>
              </b-col>
            </b-row>
          </b-col>
          <b-col v-else>
            <strong class="text-white">{{ imageResults.accuracy.avg / 100 | percentage }}</strong>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            Time:
          </b-col>
          <b-col v-if="imageResults.challengers > 1">
            <b-row>
              <b-col cols="4">
                Min: <strong class="text-white">
                  {{ getMinutes(imageResults.time.min) | zeroPad }}:{{ getSeconds(imageResults.time.min) | zeroPad }}
                </strong>
              </b-col>
              <b-col cols="4">
                Avg: <strong class="text-white">
                  {{ getMinutes(imageResults.time.avg) | zeroPad }}:{{ getSeconds(imageResults.time.avg) | zeroPad }}
                </strong>
              </b-col>
              <b-col cols="4">
                Max: <strong class="text-white">
                  {{ getMinutes(imageResults.time.max) | zeroPad }}:{{ getSeconds(imageResults.time.max) | zeroPad }}
                </strong>
              </b-col>
            </b-row>
          </b-col>
          <b-col v-else>
            <strong class="text-white">
              {{ getMinutes(imageResults.time.avg) | zeroPad }}:{{ getSeconds(imageResults.time.avg) | zeroPad }}.{{ getMilliSeconds(imageResults.time.avg) | zeroPad(3) }}
            </strong>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            You're {{ timePercentage }} {{ timeSlower }} than other participants.
          </b-col>
        </b-row>
      </div>
      <b-row v-else>
        <b-col>
          <p class="mb-0">You're the first participant to complete this image.</p>
        </b-col>
      </b-row>
      <b-button type="button" pill class="mt-3 mb-1 btn-sm btn-yellow px-5" @click.prevent="nextImage()" v-if="lastImage">Onto Phase 2!</b-button>
      <b-button type="button" pill class="mt-3 mb-1 btn-sm btn-blue px-5 ml-2" @click.prevent="viewResults()" v-if="lastImage">View Phase 1 Results</b-button>
      <b-button type="button" pill class="mt-3 mb-1 btn-sm btn-yellow px-5" @click.prevent="nextImage()" v-if="!lastImage">Next Image!</b-button>
    </div>
    <b-modal id="confirmNoPathologies" size="md" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
      <div class="text-center">
        <h4 class="mb-2">No pathologies specified</h4>
        <p>Are you sure you want to save the findings without any pathologies specified?</p>
        <b-button type="button" pill class="btn-blue px-5" @click.prevent="overrideSubmit()">Yes</b-button>
        <b-button type="button" pill class="btn-yellow px-5 ml-2" @click.prevent="restartTimer()">No</b-button>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

import BoundedBox from './BoundedBox'
import BoundedImage from './BoundedImage'
import Feedback from './Feedback'
import FullScreen from './FullScreen'

import { detailService } from '../../../services'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BoundedBox,
    BoundedImage,
    Feedback,
    FullScreen,
    VueCropper
  },
  data () {
    return {
      imgSource: '',
      boundedImageKey: 0,
      labellerKey: 0,
      colours: ['#5E50F9', '#6610f2', '#6a008a', '#E91E63', '#f96868', '#f2a654', '#F0B93B'],
      edai: ['#f2a654', '#F0B93B', '#46c35f', '#58d8a3', '#57c7d4', '#4d83ff', '#71c016'],
      reference: ['#4d83ff', '#71c016', '#7859df', '#ffc100', '#ff4747', '#5E50F9', '#6610f2'],
      dimensions: {
        w: 0,
        h: 0,
        ratio: 0.00
      },
      loading: true,
      labeller: {
        width: 0,
        height: 0
      },
      labellerContainer: {
        width: 0,
        height: 0,
        ratio: 0
      },
      current: null,
      open: {
        pathologies: false,
        diagnosis: false,
        results: false,
        enhancement: false
      },
      selectedHeatmap: null,
      key: 1,
      diagnosis: null,
      pathologies: [],
      list: [
        'Atelectasis',
        'Bone Lesion',
        'Brochiectatasis',
        'Calcified Nodules',
        'Cardiomegaly',
        'Cavity',
        'Consolidation',
        'Cysts',
        'Ground Glass',
        'Hyperinflation',
        'Infiltrates',
        'Lymphadenopathy',
        'Micronodules',
        'Mass',
        'Nodules',
        'Other',
        'Pleural Effusion',
        'Pneumomediastinum',
        'Pneumothorax',
        'Peribronchial Thickening',
        'Post Surgical Hardware',
        'Pulmonary Arteries Enlarged',
        'Reticular Infiltrates',
        'Unfolded Aorta'
      ],
      newPathology: {
        active: false,
        index: null,
        name: null,
        other: null,
        coords: {
          x: 0,
          y: 0,
          w: 0,
          h: 0
        }
      },
      edaiTime: 0.00,
      times: {
        me: 0,
        edai: 0,
        reference: 0
      },
      animateFrame: 0,
      startTime: 0,
      combined: [],
      selectedDisplay: null,
      accuracy: {
        me: 0.00,
        edai: 0.00,
        calculated: false
      },
      brightness: 100,
      contrast: 100,
      saturate: 100,
      sepia: 0,
      invert: 0,
      hue: 0,
      imageResults: null,
      showAiPathologies: false,
      showAiOverlay: false,
      showOverlay: true,
      aiDone: false
    }
  },
  props: {
    selected: {
      type: Object,
      default: null
    },
    phase: {
      type: Number,
      default: 1
    }
  },
  computed: {
    ...mapState({
      phaseOne: state => state.challenge.phaseOne,
      phaseTwo: state => state.challenge.phaseTwo,
      totals: state => state.totals,
      _user: state => state.user
    }),
    enhancementTop () {
      return '0.75'
    },
    resultsTop () {
      if (this.open.enhancement) {
        return '15.25'
      } else {
        return '0.75'
      }
    },
    pathologiesTop () {
      let _top = 0.75

      if (this.open.enhancement) {
        _top += 14.75
      }

      if (this.open.diagnosis) {
        _top += 10.5
      }

      return _top.toFixed(2)
    },
    diagnosisTop () {
      let _top = 0.75

      if (this.open.enhancement) {
        _top += 14.75
      }

      return _top.toFixed(2)
    },
    selectedImage () {
      if (this.selectedHeatmap) {
        return this.current.heatmaps.find(heatmap => heatmap.filename === this.selectedHeatmap)
      } else {
        return null
      }
    },
    filteredList () {
      if (this.current.analysis.pathologies && this.current.analysis.pathologies.length > 0) {
        const _list = this.current.analysis.pathologies.filter(_pathology => _pathology.name !== 'Thoracic' && _pathology.name !== 'Cardio')
        return _list
      } else {
        return []
      }
    },
    labellerStyle () {
      if (!this.dimensions.w) {
        return {}
      } else {
        return {
          width: this.dimensions.w.toString() + 'px',
          height: this.dimensions.h.toString() + 'px'
        }
      }
    },
    canvasLeft () {
      if (this.dimensions.w && this.labeller.width) {
        const _left = parseInt((this.labeller.width - this.dimensions.w) / 2)
        return (_left <= 0 ? 0 : _left - 8)
      } else {
        return 10
      }
    },
    hours: function () {
      return {
        me: Math.floor(this.times.me / 1000 / 60 / 60),
        edai: Math.floor(this.times.edai / 1000 / 60 / 60),
        reference: Math.floor(this.times.reference / 1000 / 60 / 60)
      }
    },
    minutes: function () {
      return {
        me: Math.floor(this.times.me / 1000 / 60) % 60,
        edai: Math.floor(this.times.edai / 1000 / 60) % 60,
        reference: Math.floor(this.times.reference / 1000 / 60) % 60
      }
    },
    seconds: function () {
      return {
        me: Math.floor(this.times.me / 1000) % 60,
        edai: Math.floor(this.times.edai / 1000) % 60,
        reference: Math.floor(this.times.reference / 1000) % 60
      }
    },
    milliSeconds: function () {
      return {
        me: Math.floor(this.times.me % 1000),
        edai: Math.floor(this.times.edai % 1000),
        reference: Math.floor(this.times.reference % 1000)
      }
    },
    timePercentage: function () {
      if (this.imageResults) {
        let perc = 0.00

        if (this.times.me > this.imageResults.time.avg) {
          perc = this.times.me / this.imageResults.time.avg
        } else {
          perc = this.imageResults.time.avg / this.times.me
        }

        return (perc - 1).toFixed(1) + 'x'
      } else {
        return null
      }
    },
    timeSlower: function () {
      if (this.imageResults) {
        return this.times.me > this.imageResults.time.avg ? 'slower' : 'faster'
      } else {
        return null
      }
    },
    refMinutes: function () {
      return Math.floor(this.selected.reference.time / 1000 / 60) % 60
    },
    refSeconds: function () {
      return Math.floor(this.selected.reference.time / 1000) % 60
    },
    refMilliSeconds: function () {
      return Math.floor(this.selected.reference.time % 1000)
    },
    lastImage () {
      if (this.phase === 1) {
        const _index = this.phaseOne.findIndex(_record => _record.image === this.selected.image)
        if (_index + 1 === this.phaseOne.length) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  created () {
    this.current = null
  },
  mounted () {
    if (this.selected) {
      let _existing = null
      if (this.phase === 1) {
        _existing = this.totals.scores.phaseOne.find(_image => _image.image === this.selected.image)
      } else if (this.phase === 2) {
        _existing = this.totals.scores.phaseTwo.find(_image => _image.image === this.selected.image)
      }
      console.log(this.selected, _existing)

      if (!_existing) {
        this.current = this.selected
        this.imgSource = this.current.image
        setTimeout(() => {
          this.getLabellerDimensions()
          this.$refs.cropper.clear()
          this.$refs.cropper.reset()
          this.$refs.cropper.enable()
          this.$refs.cropper.initCrop()
          this.$refs.cropper.replace(this.imgSource)
          this.$refs.cropper.cropper.disabled = true
          this.$refs.cropper.disable()
        }, 500)
      } else {
        this.accuracy = {
          me: _existing.accuracy / 100,
          edai: 0,
          calculated: true
        }

        this.pathologies = _existing.pathologies
        this.diagnosis = _existing.diagnosis
        this.times.me = _existing.time

        this.current = this.selected
        this.imgSource = this.current.image
        setTimeout(() => {
          this.getLabellerDimensions()
          this.$refs.cropper.clear()
          this.$refs.cropper.reset()
          this.$refs.cropper.enable()
          this.$refs.cropper.initCrop()
          this.$refs.cropper.replace(this.imgSource)
          this.$refs.cropper.cropper.disabled = true
          this.$refs.cropper.disable()
        }, 500)

        this.getImageResults()
      }
    }
  },
  methods: {
    ...mapActions('totals', {
      updateImages: 'updateImages',
      updateTime: 'updateTime',
      updateAccuracy: 'updateAccuracy',
      updateScore: 'updateScore'
    }),
    getLabellerDimensions: function () {
      this.labeller.width = this.$refs.labeller.clientWidth
      this.labeller.height = this.$refs.labeller.clientHeight
    },
    cropImage: function (event) {
      const data = this.$refs.cropper.getData(true)
      this.newPathology.coords = {
        x: data.x,
        y: data.y,
        w: data.width,
        h: data.height
      }
    },
    onReady: function (evt) {
      const imageData = this.$refs.cropper.getImageData()
      this.dimensions = {
        w: parseInt(imageData.width),
        h: parseInt(imageData.height),
        ratio: (imageData.width / imageData.naturalWidth + imageData.height / imageData.naturalHeight) / 2
      }
      this.boundedImageKey += 1
    },
    toggle: function (slidein) {
      this.open[slidein] = !this.open[slidein]
    },
    toggleAiOverlay: function () {
      this.showAiOverlay = !this.showAiOverlay
    },
    toggleOverlay: function () {
      this.showOverlay = !this.showOverlay
    },
    onSumbit: function () {
      this.submit()
    },
    submit: function () {
      if (this.newPathology.active) {
        const imageData = this.$refs.cropper.cropper.imageData
        this.dimensions = {
          w: parseInt(imageData.width),
          h: parseInt(imageData.height),
          ratio: (imageData.width / imageData.naturalWidth + imageData.height / imageData.naturalHeight) / 2
        }
        const data = this.$refs.cropper.getData(true)
        this.newPathology.coords = {
          x: data.x,
          y: data.y,
          w: data.width,
          h: data.height
        }
        if (this.newPathology.index != null) {
          this.pathologies.forEach((pathology, index) => {
            if (this.newPathology.index === index) {
              pathology.name = this.newPathology.name
              pathology.other = this.newPathology.other
              pathology.coords = this.newPathology.coords
            }
          })
        } else {
          this.pathologies.push({
            name: this.newPathology.name,
            other: this.newPathology.other,
            coords: this.newPathology.coords
          })
        }
        this.newPathology.active = false
        this.newPathology.index = null
        this.newPathology.name = null
        this.newPathology.other = null
        this.newPathology.coords = {
          x: 0,
          y: 0,
          w: 0,
          h: 0
        }
        this.$refs.cropper.clear()
        this.$refs.cropper.reset()
        this.$refs.cropper.disable()
      } else {
        this.newPathology.active = true
        this.$refs.cropper.enable()
        this.$refs.cropper.initCrop()
      }
    },
    edit: function (index) {
      this.pathologies.forEach((pathology, idx) => {
        if (index === idx) {
          this.newPathology = {
            active: true,
            index: idx,
            name: pathology.name,
            other: pathology.other,
            coords: pathology.coords
          }

          this.$refs.cropper.enable()
          this.$refs.cropper.initCrop()
          this.$refs.cropper.setCropBoxData({
            height: pathology.coords.h * this.dimensions.ratio,
            width: pathology.coords.w * this.dimensions.ratio,
            left: pathology.coords.x * this.dimensions.ratio,
            top: pathology.coords.y * this.dimensions.ratio
          })
        }
      })
    },
    cancelEdit: function () {
      this.newPathology.active = false
      this.newPathology.index = null
      this.newPathology.name = null
      this.newPathology.other = null
      this.newPathology.coords = {
        x: 0,
        y: 0,
        w: 0,
        h: 0
      }
      this.$refs.cropper.clear()
      this.$refs.cropper.reset()
      this.$refs.cropper.disable()
    },
    remove: function (index) {
      const _temp = []
      this.pathologies.forEach((pathology, idx) => {
        if (idx !== index) {
          _temp.push(pathology)
        }
      })

      this.pathologies = _temp
      this.boundedImageKey += 1
    },
    startTimer: function () {
      const self = this
      if (self.startTime === 0) {
        self.startTime = Math.floor(performance.now())
      }
      (function loop () {
        const nowTime = Math.floor(performance.now())
        const diffTime = nowTime - self.startTime
        if (diffTime <= self.edaiTime) {
          self.times.edai = diffTime
        } else {
          self.aiDone = true
        }
        if (diffTime <= self.selected.reference.time) {
          self.times.reference = diffTime
        } else {
          self.times.reference = self.selected.reference.time
        }
        self.times.me = diffTime
        self.animateFrame = requestAnimationFrame(loop)
      }())
      self.isRunning = true
    },
    stopTimer: function () {
      this.isRunning = false
      cancelAnimationFrame(this.animateFrame)
    },
    clearAll: function () {
      this.stopTimer()
      this.startTime = 0
      this.animateFrame = 0
      this.times.me = 0
      this.times.edai = 0
      this.times.reference = 0
    },
    getImageResults: function () {
      const self = this
      detailService.getImage(this._user.info._id, this.selected.image)
        .then(
          image => {
            if (image) {
              self.imageResults = image
            }
          }
        )
    },
    noPathologies: function () {
      this.stopTimer()

      this.pathologies = []
      this.diagnosis = 'Normal'

      this.accuracy.me = this.calculateAccuracy(this.pathologies, this.selected.reference.pathologies) * 0.7
      this.accuracy.me += this.stringSimilarity(this.diagnosis, this.selected.reference.diagnosis) * 0.3
      this.accuracy.edai = this.calculateAccuracy(this.selected.edai.pathologies, this.selected.reference.pathologies)

      this.select('reference')
      this.accuracy.calculated = true

      this.updateImages()
      this.updateTime(this.times.me)
      this.updateAccuracy({
        _id: this._user.info._id,
        accuracy: this.accuracy.me * 100
      })
      this.updateScore({
        _id: this._user.info._id,
        phase: this.phase,
        score: {
          image: this.selected.image,
          time: this.times.me,
          accuracy: this.accuracy.me * 100,
          pathologies: this.pathologies,
          diagnosis: this.diagnosis
        }
      })

      this.getImageResults()
    },
    submitImage: function () {
      this.stopTimer()

      if (this.newPathology.active) {
        this.onSumbit()
      }

      if (this.pathologies && this.pathologies.length > 0) {
        this.accuracy.me = this.calculateAccuracy(this.pathologies, this.selected.reference.pathologies) * 0.7
        this.accuracy.me += this.stringSimilarity(this.diagnosis, this.selected.reference.diagnosis) * 0.3
        this.accuracy.edai = this.calculateAccuracy(this.selected.edai.pathologies, this.selected.reference.pathologies)

        this.select('reference')
        this.accuracy.calculated = true

        this.updateImages()
        this.updateTime(this.times.me)
        this.updateAccuracy({
          _id: this._user.info._id,
          accuracy: this.accuracy.me * 100
        })
        this.updateScore({
          _id: this._user.info._id,
          phase: this.phase,
          score: {
            image: this.selected.image,
            time: this.times.me,
            accuracy: this.accuracy.me * 100,
            pathologies: this.pathologies,
            diagnosis: this.diagnosis
          }
        })

        this.getImageResults()
      } else {
        this.$bvModal.show('confirmNoPathologies')
      }
    },
    restartTimer: function () {
      this.$bvModal.hide('confirmNoPathologies')
      this.startTimer()
    },
    overrideSubmit: function () {
      this.$bvModal.hide('confirmNoPathologies')
      this.stopTimer()

      if (this.newPathology.active) {
        this.onSumbit()
      }

      this.accuracy.me = this.calculateAccuracy(this.pathologies, this.selected.reference.pathologies) * 0.7
      this.accuracy.me += this.stringSimilarity(this.diagnosis, this.selected.reference.diagnosis) * 0.3
      this.accuracy.edai = this.calculateAccuracy(this.selected.edai.pathologies, this.selected.reference.pathologies)

      this.select('reference')
      this.accuracy.calculated = true

      this.updateImages()
      this.updateTime(this.times.me)
      this.updateAccuracy({
        _id: this._user.info._id,
        accuracy: this.accuracy.me * 100
      })
      this.updateScore({
        _id: this._user.info._id,
        phase: this.phase,
        score: {
          image: this.selected.image,
          time: this.times.me,
          accuracy: this.accuracy.me * 100,
          pathologies: this.pathologies,
          diagnosis: this.diagnosis
        }
      })

      this.getImageResults()
    },
    select: function (toggle) {
      this.selectedDisplay = toggle
      this.combined = []

      if (toggle === 'me') {
        this.pathologies.forEach((pathology, index) => {
          this.combined.push({
            name: pathology.name,
            other: pathology.other,
            colour: this.colours[index],
            coords: pathology.coords
          })
        })
      } else if (toggle === 'edai') {
        this.selected.edai.pathologies.forEach((pathology, index) => {
          this.combined.push({
            name: 'AI: ' + pathology.name,
            colour: this.edai[index],
            coords: pathology.coords
          })
        })
      } else {
        this.selected.reference.pathologies.forEach((pathology, index) => {
          this.combined.push({
            name: 'GT: ' + pathology.name,
            colour: this.reference[index],
            coords: pathology.coords
          })
        })
      }

      this.boundedImageKey += 1
    },
    removeNonAlphanumeric: (inputString) => {
      return inputString.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()
    },
    stringSimilarity: function (str1, str2) {
      const len1 = str1.length
      const len2 = str2.length
      const matrix = []

      for (let i = 0; i <= len1; i++) {
        matrix[i] = [i]
        for (let j = 1; j <= len2; j++) {
          if (i === 0) {
            matrix[i][j] = j
          } else {
            const cost = str1[i - 1] === str2[j - 1] ? 0 : 1
            matrix[i][j] = Math.min(
              matrix[i - 1][j] + 1,
              matrix[i][j - 1] + 1,
              matrix[i - 1][j - 1] + cost
            )
          }
        }
      }

      const _score = ((len1 + len2) - matrix[len1][len2]) / (len1 + len2)
      console.log('Similarity Score', _score)
      return _score
    },
    calculateAccuracy: function (candidate, reference) {
      const scores = []

      this.list.filter(_pathology => _pathology !== 'Other').forEach(_pathology => {
        const _reference = reference.filter(_record => _record.name === _pathology)
        const _candidate = candidate.filter(_record => _record.name === _pathology)

        if (_reference.length === 0) {
          if (_candidate.length !== 0) {
            scores.push({
              name: _pathology,
              score: 0
            }) // False Positive
          }
        } else {
          if (_candidate.length !== 0) {
            _candidate.forEach((_cPathology, _cIndex) => {
              const _ious = []
              _reference.forEach((_rPathology, _rIndex) => {
                _ious.push(this.calculateIoU(_cPathology, _rPathology))
              })

              if (_ious.reduce((a, b) => a + b, 0) === 0) {
                scores.push({
                  name: _pathology,
                  score: 0
                }) // False Positive, label in the wrong location
              } else {
                scores.push({
                  name: _pathology,
                  score: Math.max(..._ious)
                })
              }
            })

            _reference.forEach((_rPathology, _cIndex) => {
              const _ious = []
              _candidate.forEach((_cPathology, _rIndex) => {
                _ious.push(this.calculateIoU(_cPathology, _rPathology))
              })

              if (_ious.reduce((a, b) => a + b, 0) === 0) {
                scores.push({
                  name: _pathology,
                  score: 0
                }) // False Positive, label in the wrong location
              } else {
                if (!scores.find(_score => _score.name === _pathology && _score.score === Math.max(..._ious))) {
                  scores.push({
                    name: _pathology,
                    score: Math.max(..._ious)
                  })
                }
              }
            })
          } else {
            scores.push({
              name: _pathology,
              score: 0
            }) // False Negative
          }
        }
      })

      const _referenceOther = reference.filter(_pathology => !this.list.find(_item => _item === _pathology.name))
      if (_referenceOther && _referenceOther.length > 0) {
        _referenceOther.forEach((_rPathology, _rIndex) => {
          const _candidate = candidate.filter(_pathology => _pathology.name === 'Other').filter(_record => this.removeNonAlphanumeric(_record.other) === this.removeNonAlphanumeric(_rPathology.name))

          if (_candidate.length !== 0) {
            const _ious = []
            _candidate.forEach((_cPathology, _cIndex) => {
              _ious.push(this.calculateIoU(_cPathology, _rPathology))
            })

            console.log(JSON.stringify(_ious))
            if (_ious.reduce((a, b) => a + b, 0) === 0) {
              scores.push({
                name: this.removeNonAlphanumeric(_rPathology.name),
                score: 0
              }) // False Positive, label in the wrong location
            } else {
              console.log('Should add here')
              if (!scores.find(_score => _score.name === this.removeNonAlphanumeric(_rPathology.name) && _score.score === Math.max(..._ious))) {
                scores.push({
                  name: this.removeNonAlphanumeric(_rPathology.name),
                  score: Math.max(..._ious)
                })
              }
            }
          } else {
            scores.push({
              name: this.removeNonAlphanumeric(_rPathology.name),
              score: 0
            }) // False Negative
          }
        })
      }

      const _candidateOther = candidate.filter(_pathology => _pathology.name === 'Other')
      if (_candidateOther && _candidateOther.length > 0) {
        _candidateOther.forEach((_cPathology, _cIndex) => {
          const _reference = reference.filter(_record => this.removeNonAlphanumeric(_record.name) === this.removeNonAlphanumeric(_cPathology.other))

          if (_reference.length === 0) {
            scores.push({
              name: this.removeNonAlphanumeric(_cPathology.other),
              score: 0
            }) // False Positive
          } else {
            const _ious = []
            _reference.forEach((_rPathology, _rIndex) => {
              _ious.push(this.calculateIoU(_cPathology, _rPathology))
            })

            if (_ious.reduce((a, b) => a + b, 0) === 0) {
              scores.push({
                name: this.removeNonAlphanumeric(_cPathology.other),
                score: 0
              }) // False Positive, label in the wrong location
            } else {
              if (!scores.find(_score => _score.name === this.removeNonAlphanumeric(_cPathology.other) && _score.score === Math.max(..._ious))) {
                scores.push({
                  name: this.removeNonAlphanumeric(_cPathology.other),
                  score: Math.max(..._ious)
                })
              }
            }
          }
        })
      }

      if (scores.length > 0) {
        console.log(scores)
        return (scores.reduce((a, b) => a + b.score, 0) / scores.length)
      } else {
        if (candidate.length === 0 && reference.length === 0) {
          return 1
        } else {
          return 0
        }
      }
    },
    calculateIoU: function (candidate, reference) {
      const xA = Math.max(candidate.coords.x, reference.coords.x)
      const yA = Math.max(candidate.coords.y, reference.coords.y)
      const xB = Math.min(candidate.coords.x + candidate.coords.w, reference.coords.x + reference.coords.w)
      const yB = Math.min(candidate.coords.y + candidate.coords.h, reference.coords.y + reference.coords.h)

      if (xB < xA || yB < yA) {
        return 0
      } else {
        const interArea = (xB - xA) * (yB - yA)

        const boxAArea = candidate.coords.w * candidate.coords.h
        const boxBArea = reference.coords.w * reference.coords.h

        const _iou = interArea / (boxAArea + boxBArea - interArea)
        if (_iou < 0.4) {
          return _iou * (3.00 - (_iou * 2))
        } else {
          return _iou * (2.00 - (_iou < 0.6 ? _iou * 1.45 : _iou))
        }
      }
    },
    calculateScore: function (coords1, coords2) {
      let accuracy = 0.00

      if (coords1.w > coords2.w) {
        accuracy += coords2.w / coords1.w
      } else {
        accuracy += coords1.w / coords2.w
      }

      if (coords1.h > coords2.h) {
        accuracy += coords2.h / coords1.h
      } else {
        accuracy += coords1.h / coords2.h
      }

      if (coords1.x > coords2.x) {
        accuracy += coords2.x / coords1.x
      } else {
        accuracy += coords1.x / coords2.x
      }

      if (coords1.y > coords2.y) {
        accuracy += coords2.y / coords1.y
      } else {
        accuracy += coords1.y / coords2.y
      }

      return accuracy / 4
    },
    formatTime: function (duration) {
      const hours = Math.floor(duration / 1000 / 60 / 60)
      const minutes = Math.floor(duration / 1000 / 60) % 60
      const seconds = Math.floor(duration / 1000) % 60
      return (hours > 0 ? hours + 'h ' : '') + minutes + 'm ' + seconds + 's'
    },
    formatAccuracy: function (accuracy) {
      return accuracy.toFixed(2) + '%'
    },
    nextImage: function () {
      this.open = {
        pathologies: false,
        diagnosis: false,
        results: false,
        enhancement: false
      }
      this.$emit('next-image', true)
    },
    viewResults: function () {
      this.open = {
        pathologies: false,
        diagnosis: false,
        results: false,
        enhancement: false
      }
      this.$emit('view-results', true)
    },
    getMinutes: function (time) {
      return Math.floor(time / 1000 / 60) % 60
    },
    getSeconds: function (time) {
      return Math.floor(time / 1000) % 60
    },
    getMilliSeconds: function (time) {
      return Math.floor(time % 1000)
    }
  },
  watch: {
    'dimensions.w': function (_new, _old) {
      if (_old === 0 && _new !== _old) {
        if (this.dimensions.ratio >= 1 || this.labeller.width > (this.dimensions.w + 20)) {
          this.labellerContainer.width = this.dimensions.w
          this.labellerContainer.height = this.dimensions.h
          this.labellerContainer.ratio = this.dimensions.ratio

          this.labellerKey += 1

          setTimeout(() => {
            this.boundedImageKey += 1
            this.$refs.cropper.clear()
            this.$refs.cropper.reset()
            this.$refs.cropper.enable()
            this.$refs.cropper.initCrop()
            this.$refs.cropper.replace(this.imgSource)
            this.$refs.cropper.cropper.disabled = true
            this.$refs.cropper.disable()

            if (this.accuracy.calculated) {
              this.accuracy.edai = this.calculateAccuracy(this.selected.edai.pathologies, this.selected.reference.pathologies)
              this.times.edai = Math.floor(Math.random() * (2500 - 500 + 1) + 500)
              this.select('reference')
              this.loading = false
            } else {
              this.edaiTime = Math.floor(Math.random() * (2500 - 500 + 1) + 500)
              this.open = {
                pathologies: true,
                diagnosis: true,
                results: false,
                enhancement: false
              }
              this.startTimer()
              this.loading = false
            }
          }, 500)
        }
      }
    },
    'accuracy.calculated': function (calculated) {
      if (calculated) {
        this.open = {
          pathologies: false,
          diagnosis: false,
          results: true,
          enhancement: false
        }
      }
    },
    aiDone (value) {
      if (value && this.phase === 2) {
        this.pathologies = this.selected.edai.pathologies
      }
    }
  },
  filters: {
    zeroPad: function (value, num) {
      num = typeof num !== 'undefined' ? num : 2
      return value.toString().padStart(num, '0')
    },
    percentage: function (value) {
      return (value * 100).toFixed(2) + '%'
    }
  }
}
</script>

<style scoped>
.slidein {
  width: 38rem;
  position: fixed;
  z-index: 100;
  left: -100%;
  box-shadow: 1px 1px 10px rgba(0,0,0,.5);
  transition: all .5s ease-in-out;
}

.enhancement {
  top: 5rem !important;
  height: 14rem;
}

.diagnosis {
  top: 19.5rem !important;
  height: 9.75rem;
  max-height: 9.75rem;
}

.pathologies {
  top: 29.75rem !important;
  max-height: 30rem;
}

.results {
  top: 19.5rem !important;
  min-height: 20rem;
}
.open {
  left: 0;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0e2238;
  background: -moz-linear-gradient(right,  #1c7fd4 -50%, #15385B 100%);
  background: -webkit-linear-gradient(right,  #1c7fd4 -50%,#15385B 100%);
  background: linear-gradient(to right,  #1c7fd4 -50%,#15385B 100%);
}

.loader .spinner {
  margin-top: 25rem;
  width: 3rem;
  height: 3rem;
}

@media screen and (max-width: 767px) {
  .slidein {
    width: 99.5%;
    position: fixed;
    z-index: 100;
    left: -100%;
    box-shadow: 1px 1px 10px rgba(0,0,0,.5);
    transition: all .5s ease-in-out;
  }

  .enhancement {
    top: 5rem !important;
    height: 14rem;
  }

  .diagnosis {
    top: 19.5rem !important;
    height: 9.75rem;
    max-height: 9.75rem;
  }

  .pathologies {
    top: 29.75rem !important;
    max-height: 30rem;
  }

  .results {
    top: 19.5rem !important;
    min-height: 20rem;
  }
  .open {
    left: 0;
  }
}
</style>
